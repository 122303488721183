import Image from 'next/legacy/image';
import Link from 'next/link';
import React from 'react';
import { AnalyticsClientSingleton } from '~/clients/AnalyticsClient';
import { CalloutButton } from '~/components/common/Button';
import { Icon } from '~/components/common/Icon';
import useListenUrlHash from '../../hooks/useListenUrlHash';
import { FAQ_SECTION, FEATURES, THE_GAMES } from '../../sectionNames';
import BlogPopover from '../../components/BlogPopover';
import { getCookie } from 'cookies-next';
import { useRouter } from 'next/router';
interface DesktopHeaderProps {
  blogData: Array<{ title: string; slug: string }>;
}

const DesktopHeader: React.FC<DesktopHeaderProps> = ({ blogData }) => {
  const { pathname } = useRouter();
  const currentHash = useListenUrlHash();

  const navItems = [
    { href: `/#${FEATURES}`, text: 'Features' },
    { href: `/#${THE_GAMES}`, text: 'The Games' },
    { href: `/#${FAQ_SECTION}`, text: 'FAQ' },
  ];

  const renderNavItem = (item: { href: string; text: string }) => (
    <a key={item.text} href={item.href} className='relative h-fit w-fit'>
      <div className='relative'>
        <div
          className={`text-white p-2.5 text-lg leading-[100%] drop-shadow-[0_4px_4px_rgba(0,0,0,0.45)] hover:cursor-pointer space-grostek-regular font-medium text-center hover:text-coldsnap4 transition-colors hover:ring-2 hover:ring-heatwave7/10 rounded-lg overflow-hidden  ${
            currentHash === item.href.split('#')[1] ? 'bg-heatwave7/20 z-[1]' : 'bg-transparent'
          } `}
        >
          <span
            className={`${
              currentHash === item.href.split('#')[1]
                ? '[text-shadow:_0px_-1px_2px_rgb(0_0_0_/_18%)]'
                : ''
            } z-[10] relative whitespace-nowrap`}
          >
            {item.text}
          </span>
          <div
            className={`absolute h-[70px] w-[120px] scale-y-[2] scale-x-[1.1] -left-5 top-1/2  overflow-hidden  ${
              currentHash === item.href.split('#')[1] ? 'inline' : 'hidden'
            }`}
          >
            <Image
              src='/assets/landingB2BAug23/gradients/menu_gradient.webp'
              alt='gradient'
              height={70}
              width={120}
            />
          </div>
        </div>
        <div
          className={`pointer-events-none absolute h-fit w-fit bottom-[5px] z-[72] left-[47%] scale-[2]  -translate-x-[50%] transition-all ease-in-out duration-[250ms] drop-shadow-[0px_0px_4px_rgba(94,252,214,1)] ${
            currentHash === item.href.split('#')[1] ? 'opacity-100' : 'opacity-0 -bottom-[55%]'
          } `}
        >
          <div className='[&>div]:absolute'>
            <Icon name='menu_diamond' color='coldsnap4' size='small' />
            <div className='[&>div]:absolute scale-[0.6] top-[1px] left-[1px]'>
              <Icon name='menu_diamond' color='#B4FEEC' size='small' />
            </div>
          </div>
        </div>
      </div>
    </a>
  );

  return (
    <div className='flex justify-center fixed inset-x-0 max-w-full mx-auto py-[1.2rem] pl-[2.5rem] pr-[2.5rem] border-b-1 border-heatwave1/40 z-30 top-2 backdrop-blur-[50px] select-none'>
      <div className='absolute h-full w-full left-0 top-0 bg-[length:4px_4px] opacity-[0.04] [background-position:0_0,_2px_2px] pointer-events-none z-[4]' />
      <div
        // style={appear}
        className='h-full w-full max-w-[1500px] flex justify-between items-center'
      >
        <div className='hover:cursor-pointer pr-16'>
          <Link prefetch={false} href='/' legacyBehavior>
            <div className='relative w-[225px] h-[36px] scale-x-110 scale-y-110'>
              <Image
                src='/common/brand/logo/logo_text.svg'
                alt='logo'
                priority={true}
                layout='fill'
              />
            </div>
          </Link>
        </div>

        {!pathname.startsWith('/blog') ? (
          <div className='gap-2.5 flex space-x-0.5 min-w-[20%] lg:min-w-[33%]'>
            {navItems.map(renderNavItem)}
            <BlogPopover blogData={blogData} />
          </div>
        ) : (
          <div className='flex flex-1 justify-start items-center space-x-12'>
            <a className='text-coldsnap4 text-xl font-bold hover:underline' href={`/`}>
              Team Building + Icebreaker Games
            </a>
            <div className='flex items-center space-x-8'>
              <a href={`/`} className='relative h-fit w-fit'>
                <div className='relative'>
                  <div
                    className={`text-white hover:underline leading-none xl:text-lg text-base hover:cursor-pointer space-grostek-regular font-medium text-center hover:text-coldsnap4 overflow-hidden`}
                  >
                    <span className={`z-[10] relative whitespace-nowrap`}>How It Works</span>
                  </div>
                </div>
              </a>
              <a href={`/#${THE_GAMES}`} className='relative h-fit w-fit'>
                <div className='relative'>
                  <div
                    className={`text-white hover:underline leading-none xl:text-lg text-base hover:cursor-pointer space-grostek-regular font-medium text-center hover:text-coldsnap4 overflow-hidden`}
                  >
                    <span className={`z-[10] relative whitespace-nowrap`}>Free Games</span>
                  </div>
                </div>
              </a>
            </div>
          </div>
        )}
        <div className='relative flex items-center justify-center gap-x-6 ml-2'>
          <div className='w-[180px] h-11'>
            <Link
              prefetch={false}
              href={getCookie('privy:token') ? '/dashboard' : '/login'}
              className='w-full h-11'
              legacyBehavior
            >
              <CalloutButton
                text='PLAY NOW'
                overrides='!py-2'
                padding='none'
                onClick={() => AnalyticsClientSingleton.getInstance().callToActionClicked()}
              />
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DesktopHeader;
